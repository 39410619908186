import * as Sentry from "@sentry/nuxt";
import conf from "./package.json"

Sentry.init({
    // If set up, you can use your runtime config here
    // dsn: useRuntimeConfig().public.sentry.dsn,
    dsn: useRuntimeConfig().public.sentry,
    release: conf.version,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
});
console.log('SENTRY init')